import httpRequst from "../utils/request";
// 订单列表
export const orderList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/orders`, params)
}
// 取消订单 api/orders/cancel/:id
export const cancelOrder = function(id,params) {
    return httpRequst.put(`${process.env.VUE_APP_BASEURL}/api/orders/cancel/${id}`, params)
}
// 确认收货
export const confirmReceipt = function(id,params) {
    return httpRequst.put(`${process.env.VUE_APP_BASEURL}/api/orders/confirm-receipt/${id}`, params)
}
// 订单详情
export const orderDetail = function(id,params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/orders/${id}`, params)
}

// 申请售后
export const orderAftersales = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/orders/aftersales`, params)
}


// 申请售后
export const logisticsInfo = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/orders/logistics-info`, params)
}
