<template>
    <div class="pl-pay-wrap" v-if="show" @click="canclePay">
        <canvas :id="elem" width="200" height="200"> </canvas>
    </div>
</template>
<script>
import QRCode from 'qrcode'
import {orderDetail} from '@/api/order.js'
export default {
    props:['id','wxurl','show'],
    data() {
        return {
            stop: '',
            elem: `pay${Math.floor(Math.random()*100)}`
        }
    },
    methods: {
        init(){
            let url = this.wxurl
            if(!url) {
                return false
            }
            let elem = document.getElementById(this.elem)
            QRCode.toCanvas(elem, url, res => {
                console.log(res)
            })
            clearInterval(this.stop)
            this.stop = setInterval(() => {
                if(!this.id) {
                    clearInterval(this.stop)
                    return false
                }
                orderDetail(this.id).then(({status}) => {
                    if(status > 1) {
                        // 支付成功
                        this.$router.push('/user/shopping/success?id=' +this.id)
                        this.$emit('update:show', false)
                        clearInterval(this.stop)
                    } else if(status == 0) {
                        // 支付失败
                        this.$router.push('/user/shopping/fail?id='+this.id)
                        this.$emit('update:show', false)
                        clearInterval(this.stop)
                    }
                })
            }, 1000);
        },
        canclePay() {
            clearInterval(this.stop)
            this.$emit('update:show', false)
        }
    },
    watch: {
        wxurl() {
            this.$nextTick(()=>{
                this.init()
            })
        }
    },
    destroyed() {
        clearInterval(this.stop)
    }
}
</script>
<style lang="scss" scoped>
.pl-pay-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.8);
    z-index: 99;
    canvas {
        position: absolute;
        left: 50%;
        top: 50%;
        translate: -50%;
    }
}
</style>